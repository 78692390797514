(function(W) {
  'use strict';

  var $ = W.jQuery,
      _ = W._,
      toastr = W.toastr;

  var submitLock = false;
  var submitAjax = [];
  $(document).ajaxSend(function(e, jqXHR) {
    if (submitLock) submitAjax.push(jqXHR);
  });

  $.fn.submit = function(data, fn) {
    if (arguments.length < 1) {
      this.trigger('submit');
      return this;
    }

    if (typeof data === 'function') {
      var t = data;
      data = fn;
      fn = t;
    }
    
    var selected = this;
    var handler = function() {
      selected.clearErrors().find('button[type=submit], input[type=submit]').attr('disabled', 'disabled');

      // call the original handler
      submitLock = true;
      var result = fn.apply(this, arguments);
      $.when(result).then(function() {}, function() {}).then(function() {
        // we don't care what the result was, or whether it failed - just that it completed
        submitLock = false;
        var tasksRunning = [{}];
        while(submitAjax.length) tasksRunning.push(submitAjax.shift());
        $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
          // again we don't care the results - just that they are all finished
          selected.one('submit', null, data, handler);
          selected.find('button[type=submit], input[type=submit]').removeAttr('disabled');
        });
      });
      return result;
    };
    return this.one('submit', null, data, handler);
  };

  $.fn.ajaxData = function(tinyMCE) {
    var data = {}, selfForm = this;
    $.each(this.serializeArray(), function(i, field) {
      var $chk = selfForm.find('input[type=checkbox][name="' + field.name + '"]:not(.submit-value)');
      if ($chk.length) {
        field.value = $chk.is(':checked') ? 'true' : 'false';
      }
      if (typeof data[field.name] === 'undefined') {
        _.set(data, field.name, field.value);
      } else {
        var current = _.get(data, field.name);
        if (!current.push || typeof current.push !== 'function') {
          current = [current];
          _.set(data, field.name, current);
        }
        current.push(field.value);
      }
    });

    this.find('input[type=checkbox]:not(.submit-value):not(:checked)').each(function() {
      if (!this.name) return;
      _.set(data, this.name, 'false');
    });

    this.find('select[multiple]').each(function() {
      _.set(data, this.name, $(this).val());
    });

    if (tinyMCE && tinyMCE.editors) {
      _.each(tinyMCE.editors, function(e) {
        var frmElement = selfForm.find('#' + e.id);
        if (frmElement.length) {
          data[frmElement.attr('name')] = e.getContent();
        }
      });
    }
    
    this.trigger('snd:extraFormData', [data]);
    return data;
  };

  $.fn.clearErrors = function() {
    this.find('.hasError').removeClass('hasError').off('mousemove', errorTooltipPosition);
    this.find('.form-group.has-error').removeClass('has-error').off('mousemove', errorTooltipPosition);
    this.find('select.select2:not(.hasError)  + .select2-container .select2-selection__rendered').each(function() {
      var tmpTitle = $(this).data('snd:tmp-err-title');
      if (tmpTitle && !$(this).closest('.has-error').length) {
        $(this).data('snd:tmp-err-title', null);
        $(this).attr('title', tmpTitle);
      }
    });
    return this;
  };

  $.handleFormErrors = function(selector, callback) {
    if (typeof selector === 'function') {
      callback = selector;
      selector = null;
    }

    if (typeof callback !== 'function') callback = null;

    return function(result, status, xhr) {
      if (result.status === 0) {
        toastr.error("<strong>Connection Issue</strong>: There is an issue connecting to the server right now");
      } else {
        if (W.util.IsJSON(result.responseText)) {
          if (JSON.parse(result.responseText).errors != null) {
            result.response = JSON.parse(result.responseText);
            if (result.response.errors) {
              _.each(result.response.errors, function(err){
                console.log(result.response.errors);
                var message = err.message;
                if (err.extra) {
                  if (err.extra.redirect) message = message += '<br><a href=' + err.extra.redirect.link + '><strong>' + err.extra.redirect.label + '</strong></a>';
                }
                toastr.error(message);
                handleFieldError(err, selector);
              });
            }
            if (result.response.message) {
              toastr.error(result.response.message);
            }
          }
        } else {
          toastr.error(result.responseText);
        }
      }
      if (callback) return callback.call($(selector), result, status, xhr);
    };
  };

  function handleFieldError(err, selector) {
    if (typeof err === 'string') {
      err = {
        param: (typeof selector === 'object' ? '#'+$(selector).attr('id') : 'snd-bogus-selector'),
        message: err
      };
      selector = null;
    }
    
    var fldSelector = /^[\#\. \[\]]/.test(err.param) ? err.param : '[name="' + err.param + '"]';
    var fld = selector ? $(selector).find(fldSelector) : $(fldSelector);
    
    if (fld.length) {
      var fldGroup = fld.closestWithin('.form-group', 3);
      // var tooltipOptions = {
      //   placement: 'auto top',
      //   trigger: 'hover',
      //   title: err.message,
      //   template: `<div class="tooltip has-error" role="tooltip">
      //     <div class="tooltip-arrow"></div>
      //     <div class="tooltip-inner"></div>
      //   </div>`
      // };
      if (fldGroup.length) {
        fldGroup.addClass('has-error');
        // fldGroup.tooltip(tooltipOptions);
        fldGroup.data('err-msg', err.message);
        fldGroup.on('mousemove', errorTooltipPosition);
      } else {
        fld.addClass('hasError');
        // fld.tooltip(tooltipOptions);
        fld.data('err-msg', err.message);
        fld.on('mousemove', errorTooltipPosition);
      }
    }
    
    if (!fld.length && selector) {
      $(selector).trigger('snd:extraFormErrors', [err]);
    }
  }
  $.handleFieldError = function(err, selector) {
    return handleFieldError(err, selector);
  };

  function errorTooltipPosition(e) {
    var tooltipID = $(e.currentTarget).attr('aria-describedby');
    var $tooltip = $('#' + tooltipID);
    if (!$tooltip.length) return;

    var actualWidth = $tooltip[0].offsetWidth;
    //var newLeft = e.pageX - $(this.offsetParent).offset().left - (actualWidth / 2);
    var newLeft = e.pageX - (actualWidth / 2);
    newLeft = Math.round(newLeft);
    $tooltip.css({
      left: newLeft + 'px',
      'z-index': 999999
    });
  }

  $.hiddenSubmit = function(frm, data) {
    var form = document.createElement('form');
    form.action = frm.url;
    form.method = frm.method || 'POST';
    form.target = frm.target || '_self';
    if (data) {
      for (var key in data) {
        if (!data.hasOwnProperty(key)) continue;
        var input = document.createElement('textarea');
        input.name = key;
        input.value = (typeof data[key] === 'object') ? ':json:' + JSON.stringify(data[key]) : data[key];
        form.appendChild(input);
      }
    }
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
  };

  $.fn.syncInput = function(sourceSelector) {
    var source = $(sourceSelector);
    if (this.is('input')) {
      var inputType = this.attr('type');
      if (typeof inputType === typeof undefined) inputType = 'text';
      switch(inputType) {
        case 'text':
          source.off('change').data('sync-target', this).on('change', syncInput).change();
          source.off('blur').data('sync-target', this).on('blur', syncInput);          
          break;
      }
      this.prop('readonly', true);
    } else {
      source.off('change').data('sync-target', this).on('change', syncInput).change();

      var hidden = $('<input type="hidden" />').attr('name', this.attr('name')).val(this.val());
      this.prop('disabled', true);
      this.after(hidden);
    }
    this.data('sync-source', sourceSelector);
    return this;
  };

  $.fn.disableSyncInput = function() {
    var sourceSelector = this.data('sync-source');
    if (sourceSelector) {
      if (this.is('input')) {
        var inputType = this.attr('type');
        if (typeof inputType === typeof undefined) inputType = 'text';
        switch(inputType) {
          case 'text':
            $(sourceSelector).off('keyup');
            break;
        }
      } else {
        $(sourceSelector).off('change');
        
        var next = this.next();
        if (next && next.attr('type') === 'hidden' && next.attr('name') === this.attr('name')) {
          next.remove();
        }
        this.prop('disabled', false);
      }
    }
    this.prop('readonly', false);
    return this;
  };

  function syncInput(e) {
    var target = $(e.currentTarget).data('sync-target');
    if (target) {
      $(target).val($(e.currentTarget).val());
      var next = $(target).next();
      if (next && next.attr('type') === 'hidden' && next.attr('name') === $(target).attr('name')) {
        next.val($(e.currentTarget).val());
      }
    }
  }

  function ctrlSaveListener($frm) {
    return function(e) {
      if (e.which === 83 && (e.ctrlKey || e.metaKey) && !e.altKey) {
        var modal = $.topModal();
        if (modal) {
          var frm = $(modal).find('form');
          if (frm && frm.length === 1) {
            e.preventDefault();
            frm.submit();
          }
        } else {
          e.preventDefault();
          $frm.submit();
        }
      }
    };
  }

  $.fn.ctrlSave = function(command) {
    var fn = null;
    if (command === 'destroy') {
      fn = this.data('ctrlSave_fn');
      if (fn) {
        $(document).off('keydown', fn);
      }
      return this;
    }
    fn = ctrlSaveListener(this);
    $(document).on('keydown', fn);
    this.data('ctrlSave_fn', fn);
    return this;
  };

  $.fn.numericOnly = function(selector) {
    if (selector) {
      this.on('keydown', selector, numericOnlyHandler);
    } else {
      this.on('keydown', numericOnlyHandler);
    }
  };

  var numericKeyCodes = _.concat(_.range(48, 58), _.range(96, 106), 8, 9, 13, 37, 39, 46, 110, 190);
  function numericOnlyHandler(e) {
    if (e.shiftKey) e.preventDefault();
    if (!_.includes(numericKeyCodes, e.keyCode)) e.preventDefault();
    if ($(e.currentTarget).val().indexOf('.') !== -1 && (e.keyCode === 190 || e.keyCode === 110))
      e.preventDefault();
  }

  $.fn.inputGroupType = function(percentOrDollar, useFontAwesome) {
    this.each(function() {
      var $this = $(this);
      var $grp = $this.parent();
      if (!$grp.hasClass('input-group')) return;
      
      var $addon = $grp.find('.input-group-addon');
      if (percentOrDollar === '%') {
        if ($grp.children().last().hasClass('input-group-addon')) return;
        $addon.remove();
        if (useFontAwesome) {
          $grp.append('<span class="input-group-addon"><i class="fa fa-percent" /></span>');
        } else {
          $grp.append('<span class="input-group-addon">%</span>');
        }
        $grp.addClass('align-percent');
        $grp.removeClass('align-dollar');
      } else if (percentOrDollar === '$') {
        if ($grp.children().first().hasClass('input-group-addon')) return;
        $addon.remove();
        if (useFontAwesome) {
          $grp.prepend('<span class="input-group-addon"><i class="fa fa-usd" /></span>');
        } else {
          $grp.prepend('<span class="input-group-addon">$</span>');
        }
        $grp.addClass('align-dollar');
        $grp.removeClass('align-percent');
      }
    });
  };

  $(function() {
    
    $('body').tooltip({
      selector: '.has-error, .hasError',
      container: 'body',
      placement: 'auto top',
      trigger: 'hover',
      title: function() { return $(this).data('err-msg'); },
      template: '<div class="tooltip has-error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
    });
  });

})(self);