(function(W) {
  'use strict';

  var util = {
    stringify: function(obj) {
      var str = JSON.stringify(obj);
      return str.replace('"', '&quot;');
    },
    formatFileSize: function(bytes, binary) {
      if (typeof bytes !== 'number') bytes = parseInt(bytes);

      var thresh = !binary ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) return bytes + ' B';
      var units = !binary ?
        ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
        ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      
      var u = -1;
      do {
        bytes /= thresh;
        u++;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    },
    formatCurrency: function(amount, dollarSign){
      if (dollarSign === true || typeof dollarSign === 'undefined') dollarSign = '$';
      if(amount){
        if(typeof amount !== 'string' && amount.toFixed(2) != null){
          amount = amount.toFixed(2).toString();
        }
        return (dollarSign ? dollarSign : '') + amount.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
      return (dollarSign ? dollarSign : '') + '0.00';
    },
    formatPercent: function(value, decimals, sign) {
      if (!decimals && decimals !== 0) decimals = 2;
      if (!sign && sign !== false) sign = true;

      if (value) {
        value = (value * 100).toFixed(decimals).toString();
        return value.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + (sign ? '%' : '');
      }
      return '0.'.padEnd(decimals + 2, '0') + (sign ? '%' : '');
    },
    safeGet: function(data, pathArray) {
      var result = data;

      for(var i = 0; i < pathArray.length; i++) {
        var tmp = result[pathArray[i]];
        if(tmp !== undefined) {
          result = tmp;
        } else {
          return null;
        }
      }

      return result;
    },
    generateUUID: function () {
      var d = new Date().getTime();
      if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid;
    },
    IsJSON: function(data) {
      try {
        var json = JSON.parse(data); // jshint ignore:line
        return true;
      } catch(e) {
        return false;
      }
    },
    getQuery: function() {
      if (!window.location.search) return {};
      var query = {},
          qs = (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&');
      for(var i = 0; i < qs.length; i++) {
        var pv = qs[i].split('=');
        query[decodeURIComponent(pv[0])] = decodeURIComponent(pv[1] || '');
      }
      return query;
    },
    buildHref: function(path, query, hash, host) {
      var parts = [];
      for (var key in query) {
        if (!query.hasOwnProperty(key)) continue;
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(query[key]));
      }
  
      var qs = parts.length ? '?' + parts.join('&') : '';
      path = path ? path : window.location.pathname;
      hash = hash ? hash : window.location.hash;
      if (path && path[0] !== '/') path = '/' + path;
      if (host) {
        return host + path + qs + (hash ? '#' : '') + hash;
      }
      return window.location.protocol + '//' +
        window.location.hostname + 
        path + qs + (hash ? '#' : '') + hash;
    }
  };

  W.util = util;
  W.Util = util;
  W.utils = util;
  W.Utils = util;

})(self);