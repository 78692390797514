'use strict';

var $ = window.jQuery, _ = window._, toastr = window.toastr;

$.fn.wizard = function wizard(action) {
  var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
  if (args.length) args.shift();

  var $wizardContainer = this.hasClass('.wizard') ? this : this.closest('.wizard');
  switch (action) {
    case 'goto':
      gotoStep($wizardContainer, args[0]); break;
    case 'register:next':
      registerNext.apply(this, args); break;
    case 'register:prev':
      registerPrev.apply(this, args); break;
    case 'register:btn':
      registerButton.apply(this, args); break;
    case 'enable':
      setupWizard.apply(this, args); break;
    default:
      return $wizardContainer;
  }
  return this;
};

function setupWizard() {
  this.on('click', 'a[data-step], button[data-step], .btn[data-step]', wizardNavigateStep);
}

function registerPrev() {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  var $nextButtons = $wizardContainer.find('.wizard-nav .prev');
  $nextButtons.off('click');
  $nextButtons.data('step', null);

  if (arguments.length < 2) {
    // hide the button - no action
    $nextButtons.addClass('hidden');
    showHideNav();
    return;
  }
  $nextButtons.removeClass('hidden');

  if (typeof arguments[0] === 'function') {
    var callback = arguments[0];
    $nextButtons.on('click', function(e) {
      e.preventDefault();
      callback.call($wizardContainer);
    });
  } else {
    $nextButtons.data('step', arguments[0]);
    $nextButtons.on('click', wizardNavigateStep);
  }

  if (arguments.length > 1) {
    $nextButtons.text(arguments[1]);
  }
  showHideNav();
}

function registerNext() {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  var $nextButtons = $wizardContainer.find('.wizard-nav .next');
  $nextButtons.off('click');
  $nextButtons.data('step', null);

  if (arguments.length < 2) {
    // hide the button - no action
    $nextButtons.addClass('hidden');
    showHideNav();
    return;
  }
  $nextButtons.removeClass('hidden');

  if (typeof arguments[0] === 'function') {
    var callback = arguments[0];
    $nextButtons.on('click', function(e) {
      e.preventDefault();
      callback.call($wizardContainer);
    });
  } else {
    $nextButtons.data('step', arguments[0]);
    $nextButtons.on('click', wizardNavigateStep);
  }

  if (arguments.length > 1) {
    $nextButtons.text(arguments[1]);
  }
  showHideNav();
}

function showHideNav() {
  var $nav = $('.wizard-nav');
  $nav.removeClass('hidden');

  var $visible = $nav.find('button:visible, a:visible, .btn:visible');
  if (!$visible.length) 
    $nav.addClass('hidden');
}

function gotoStep($wizardContainer, stepUrl) {
  var $stepContainer = $wizardContainer.find('.step-container');
  setTimeout(function() {
    $.ajax({
      method: 'GET',
      url: stepUrl
    })
    .done(function(html) {
      $stepContainer.html(html);
      // $stepContainer.find('input:alltext:not(.normalcase)').applyUpperCase();
      // $stepContainer.enableSelect2(); 

      $wizardContainer.find('.wizard-nav .left > *:not(.prev)').remove();
      $wizardContainer.find('.wizard-nav .right > *:not(.next)').remove();
    })
    .fail(function(result) {
      toastr.warning(result.responseText);
    });
  }, 50);
}

function registerButton(position, id, $newButton) {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  // var $nextButtons = $wizardContainer.find('.wizard-nav .next');
  // $nextButtons.off('click');
  // $nextButtons.data('step', null);
  var $button = $wizardContainer.find('.wizard-nav .' + position + ' #' + id);
  $button.remove();
  if (arguments.length < 3) {
    // hide the button - no action
    return;
  }
  
  $newButton.attr('id', id);
  $newButton.detach();
  var $buttonContainer = $wizardContainer.find('.wizard-nav .' + position);
  if (position === 'left') {
    $buttonContainer.append($newButton);
  } else if (position === 'right') {
    $buttonContainer.prepend($newButton);
  }
}

function wizardNavigateStep(e) {
  e.preventDefault();
  var $wizardContainer = $(this).closest('.wizard');
  
  gotoStep($wizardContainer, $(this).data('step'));
}

$(function() {
  $('.wizard').wizard('enable');
});