'use strict';
var sString;
var tmp;
function parseDriversLicense(scanString){
  sString = scanString;
  var licenseIssuers = {
    "636033": "Alabama",
    "646059": "Alaska",
    "604427": "American Samoa",
    "636026": "Arizona",
    "636021": "Arkansas",
    "636028": "British Columbia",
    "636014": "California",
    "636056": "Caohulia",
    "636020": "Colorado",
    "636006": "Connecticut",
    "636043": "District of Columbia",
    "636011": "Delaware",
    "636010": "Florida",
    "636055": "Georgia",
    "636019": "Guam",
    "636047": "Hawaii",
    "636057": "Hidalgo",
    "636050": "Idaho",
    "636035": "Illinois",
    "636037": "Indiana",
    "636018": "Iowa",
    "636022": "Kansas",
    "636046": "Kentucky",
    "636007": "Louisiana",
    "636041": "Maine",
    "636048": "Manitoba",
    "636003": "Maryland",
    "636002": "Massachusetts",
    "636032": "Michigan",
    "636038": "Minnesota",
    "636051": "Mississippi",
    "636030": "Missouri",
    "636008": "Montana",
    "636054": "Nebraska",
    "636049": "Nevada",
    "636017": "New Brunswick",
    "636039": "New Hampshire",
    "636036": "New Jersey",
    "636009": "New Mexico",
    "636001": "New York",
    "636016": "Newfoundland",
    "636004": "North Carolina",
    "636034": "North Dakota",
    "636013": "Nova Scotia",
    "636023": "Ohio",
    "636058": "Oklahoma",
    "636012": "Ontario",
    "636029": "Oregon",
    "636025": "Pennsylvania",
    "604426": "Prince Edward Island",
    "604428": "Quebec",
    "636052": "Rhode Island",
    "636044": "Saskatchewan",
    "636005": "South Carolina",
    "636042": "South Dakota",
    "636053": "Tennessee",
    "636027": "State Department (USA)",
    "636015": "Texas",
    "636062": "US Virgin Islands",
    "636040": "Utah",
    "636024": "Vermont",
    "636000": "Virginia",
    "636045": "Washington",
    "636061": "West Virginia",
    "636031": "Wisconsin",
    "636060": "Wyoming",
    "604429": "Yukon"
  };
  var stateNumberID = {
     "Alabama":"1",
     "Alaska":"2",
     "American Samoa": null,
     "Arizona": "3",
     "Arkansas": "4",
     "British Columbia": null,
     "California": '5',
     "Caohulia": null,
     "Colorado": '6',
     "Connecticut": '7',
     "District of Columbia": "55",
     "Delaware": '8',
     "Florida": '9',
     "Georgia": "10",
     "Guam": null,
     "Hawaii": "11",
     "Hidalgo": null,
     "Idaho": "12",
     "Illinois": "13",
     "Indiana": "14",
     "Iowa": "15",
     "Kansas": "16",
     "Kentucky": "17",
     "Louisiana": "18",
     "Maine": "19",
     "Manitoba": null,
     "Maryland": 20,
     "Massachusetts": "21",
     "Michigan": '22',
     "Minnesota": '23',
     "Mississippi": '24',
     "Missouri": '25',
     "Montana": '26',
     "Nebraska": '27',
     "Nevada": '28',
     "New Brunswick": null,
     "New Hampshire": '29',
     "New Jersey": '30',
     "New Mexico": "31",
     "New York": '32',
     "Newfoundland": null,
     "North Carolina": '33',
     "North Dakota": '34',
     "Nova Scotia": null,
     "Ohio": '35',
     "Oklahoma": '36',
     "Ontario": null,
     "Oregon": '37',
     "Pennsylvania": '38',
     "Prince Edward Island": null,
     "Quebec": null,
     "Rhode Island": '39',
     "Saskatchewan": null,
     "South Carolina": '40',
     "South Dakota": '41',
     "Tennessee": '42',
     "State Department (USA)": null,
     "Texas": '43',
     "US Virgin Islands": null,
     "Utah": '44',
     "Vermont": '49',
     "Virginia": '50',
     "Washington": '51',
     "West Virginia": '52',
     "Wisconsin": '53',
     "Wyoming": '54',
     "Yukon": null
  };
  var AAMVAStandards ={
    "01":{
      "DAQ": "drivers_license_number",
      "DAB": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "02":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DCT": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "03":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DCT": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "04":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height",
      "DCF": "date_format",
      "DCG": "country",
      "DCU": "sufix",
      "DAW": "somethin"
    },
    "05":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "06":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "07":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    },
    "08":{
      "DAQ": "drivers_license_number",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender",
      "DAY": "height"
    }
  }
  var data = {
    "stateName": null,
    "AAMVAStandard": null,
    "AAMVAvNumber": null,
    "jursVersion": null,
    "escapeCharCode": 15,
    "subfileNum": null,
    "subfileType": null,
    "middle_name": "",
    "first_name": null,
    "last_name": null,
    "gender": null,
    "height": null,
    "zip_code": null,
    "state": null
  }

  // var byteArr = scannerHelperFunctions.stringToBytes(scanString);
  if(!scannerOpperations.validationChecks(scanString)){
    return null;
  }
  // var dataElementSeparator = byteArr[1];
  // var recordSeparator = byteArr[2];
  // var segmentTerminator = byteArr[3];
  //this gives us
  var startPoint = scanString.indexOf("ANSI ") + 5;
  data.stateName = scannerOpperations.getState(scanString, licenseIssuers, startPoint)
  data.AAMVAvNumber = scanString.substring(startPoint + 6, startPoint + 8)
  data.AAMVAStandard = AAMVAStandards[data.AAMVAvNumber];
  data.jursVersion = scanString.substring(startPoint + 8, startPoint + 10);
  data.subfileNum = scanString.substring(startPoint + 10, startPoint + 12);
  data.subfileType = scanString.substring(startPoint + 12, startPoint + 14);
  data.subfileOffset = scanString.substring(startPoint + 14, startPoint + 18);
  data.subfileLength = scanString.substring(startPoint + 18, startPoint + 22);
  scannerOpperations.grabIdData(startPoint+22, scanString, data);
  scannerOpperations.formatData(data, stateNumberID);
  scannerOpperations.stripFillerChars(data);
  console.log(data);
  return data;
}



var scannerOpperations = {
  validationChecks: function( scanString){
    //Checks if first character is @symbol
    if(!scanString[0] === 64){
      return false;
    }
    //Checks if file type is
    if(scanString.indexOf('ANSI ') === -1){
      return false
    }
    return true;
  },

  getState: function(scanString, licenseIssuers, startPoint){
    var idCode = scanString.substring(startPoint, startPoint + 6);
    return licenseIssuers[idCode];
  },

  grabIdData: function(startPoint, scanString, data){
    var keys = Object.keys(data.AAMVAStandard);
    var currString = "";
    var currDataType = "";
    var fillerKeys = ["DAY","DCF","DCG","DCU","DAW", "DDF", "DDE", ]
    //Iterate over the string, see if the next three keys match one of the
    //defined fields in the current standard
    for(var i = startPoint; i < scanString.length; i++){
      if(i<scanString.length - 3){
        if(scanString.charCodeAt(i) === data.escapeCharCode){
          var nextThreeChars = scanString.substring(i+1, i + 4);
          if(keys.indexOf(nextThreeChars ) !== -1){
            if(currString !== "" && currDataType !== ""){
              currString += scanString[i];
              if(data.AAMVAStandard[currDataType]){
                data[data.AAMVAStandard[currDataType]] = currString;
              }
            }
            currString = "";
            currDataType = nextThreeChars;
            i+=3;
          }
          //Check and see if any of the nonused keys are triggered
          if(fillerKeys.indexOf(nextThreeChars) !== -1){
            if(currString !== "" && currDataType !== ""){
              currString += scanString[i];
              if(data.AAMVAStandard[currDataType]){
                data[data.AAMVAStandard[currDataType]] = currString;
              }
            }
            currString = "";
            currDataType = nextThreeChars;
            i+=3;
          }
        }
        else{
          currString += scanString[i];
        }
      }
    }
    //the 04 AAMVA standard is weird when it comes to license numbers, this grabs that
    var idStart = scanString.indexOf('DLDAQ');
    if(idStart !== -1){
      //Strip the front of the license string off
      var frontStrip = scanString.substring(idStart + 5, scanString.length);
      tmp = frontStrip;
      var escapeChar = String.fromCharCode(data.escapeCharCode);
      var backIndex = frontStrip.indexOf(escapeChar + "D");
      data.drivers_license_number = frontStrip.substring(0, backIndex);
    }
    //Because why should Indiana have to follow the rules and use what everyone else does for names?
    var idStart = scanString.indexOf('DLDAA');
    if(idStart !== -1 && data.AAMVAvNumber === "02"){
      //Strip the front of the license string off
      var frontStrip = scanString.substring(idStart + 5, scanString.length);
      tmp = frontStrip;
      var escapeChar = String.fromCharCode(data.escapeCharCode);
      var backIndex = frontStrip.indexOf(escapeChar + "DAQ");
      var splitName = frontStrip.substring(0, backIndex).split("," + escapeChar);
      data.last_name = splitName[0];
      data.first_name = splitName[1];
      data.middle_name = splitName[2];
      console.log(data.middle_name);
    }
  },

  formatData: function(data, stateNumberID){
    Object.keys(data).forEach(function(key){
      var curValue = data[key];
      if(curValue){
        data[key] = scannerOpperations.stripFillerChars(curValue);
      }
    })
    if(data.first_name){
      data.first_name = scannerOpperations.stripFillerChars(data.first_name);
      //If name has a space then split and store the other name as a symbol
      var splitName = data.first_name.split();
      data.first_name = splitName[0];
      if(!data.middle_name){
        data.middle_name = splitName.splice(1).join("");
      }
    }

    if(data.zip_code){
      //Strip out the white space and insert a dash for the zip +4 format
      data.zip_code = data.zip_code.replace(/\D/g, '');
      
      if(data.zip_code.length > 5){
        var tempzip = data.zip_code.substring(0,5) + '-' +
          data.zip_code.substring(5, data.zip_code.length);
        data.zip_code = tempzip;
      }
    }

    if(data.stateName){
      if(stateNumberID[data.stateName]){
        data.state_id = stateNumberID[data.stateName];
      }
    }

    if(data.drivers_license_number){
      data.drivers_license_number = data.drivers_license_number.replace(/ /g, '');
    }

    if(data.birth_date){
      data.birth_date= data.birth_date.substring(0,2) + "/" + data.birth_date.substring(2,4) + "/" + data.birth_date.substring(4,8);
    }
  },

  //Some states use filler characters formated 'XY' this strips these out, add move patterns to here if they are found
  stripFillerChars: function(string){
    if(typeof string === 'string'){
      //Remove extra escape chars
      string = string.replace(/[^a-z0-9 ]/gi,'');
      for(var i = 0; i < string.length; i++){
        if(string[i] + string[i + 1] === 'XY'){
          return string.substring(0, i);
        }
      }
    }
    return string;
  }
};