(function(W, U) {
  'use strict';
  /* globals tinyMCE */

  var $ = W.jQuery,
      _ = W._,
      Promise = W.Promise,
      toastr = W.toastr,
      openModals = [];

  $.topModal = function() {
    if (openModals.length) {
      var modal = openModals.pop();
      openModals.push(modal);
      return $(modal);
    }
    return $();
  };

  $.modal = function(url, options) {
    if (arguments.length === 0) {
      return $('[data-modal=remote-modal]').last();
    }

    console.log(W.util);
    options = _.defaults(options || {}, { id: W.util.generateUUID() });
    var $M = $('[data-modal=remote-modal]#' + options.id);
    if (!$M.length) {
      $M = $('<div id="'+options.id+'" data-modal="remote-modal"><div class="modal-dialog"><div class="modal-content"/></div></div>')
        .css('display', 'none')
        .appendTo('body')
        .modal({ show: false });
    }

    $M.find('.modal-dialog').removeClass('modal-fill modal-lg modal-sm');
    if (options.size) {
      $M.find('.modal-dialog').addClass(options.size);
    }

    var method = 'GET';
    if (options.method) method = options.method;

    var sendRequest = { method: method, url: url };

    if (options.data) sendRequest.data = options.data;

    if (options.is_html) {
      return new Promise(function(resolve) {
        $M.data('result', false)
          .removeClass()
          .addClass('modal fade')
          .addClass(options.class)
          .find('.modal-content').html(url).end()
          .one('hidden.bs.modal', function() {
            var result = $M.data('result');
            setTimeout(function() { $M.remove(); }, 500);
            resolve(result);
          })
          .one('shown.bs.modal', function() {
            if (typeof options.shown === 'function')
              options.shown($M);
          })
          .removeClass('fade')
          .modal('show');
      });
    }

    return new Promise(function(resolve) {
      $.ajax(sendRequest).done(function(html) {
        $M.data('result', false)
          .removeClass()
          .addClass('modal fade')
          .addClass(options.class)
          .find('.modal-content').html(html).end()
          .one('hidden.bs.modal', function() {
            var result = $M.data('result');
            setTimeout(function() { $M.remove(); }, 500);
            resolve(result);
          })
          .one('shown.bs.modal', function() {
            if (typeof options.shown === 'function')
              options.shown($M);
          })
          .removeClass('fade')
          .modal('show');
      }).fail(function(result){
        if(W.util.IsJSON(result.responseText) && JSON.parse(result.responseText).errors != null){
          result.response = JSON.parse(result.responseText);
          _.each(result.response.errors, function(err){
            toastr.warning(err.message);
            var fld = $('[name=' + err.param + ']');
            if (fld.length) {
              var fldGroup = fld.closestWithin('.form-group', 3);
              if (fldGroup.length) {
                fldGroup.addClass('has-error');
              } else {
                fld.addClass('hasError');
              }
            }
          });
          toastr.error(result.response.message);
        }else{
          toastr.error(result.responseText);
        }
        resolve(false);
      });
    });
  };

  $.confirm = function(title, msg) {
    var m = $('#confirm-modal');

    if (typeof msg === 'undefined' || msg === null) { msg = title; title = U; }

    if (!m.length)
    {
      m = $('<div id="confirm-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">No</button><button type="button" class="btn btn-primary" data-dismiss="modal">Yes</button></div></div></div></div>')
          .on('click', '.btn', function(e) { m.data('result', $(e.target).is('.btn-primary'));  })
          .appendTo('body').modal({ show: false });
    }

    return new Promise(function(resolve) {
      m.data('result', false)
       .toggleClass('no-title', !!!title)
       .find('.modal-header').html(title).toggle(!!title).end()
       .find('.modal-body').html(msg).end()
       .one('hidden.bs.modal', function() { resolve(m.data('result')); })
       .modal('show');
    });
    
  };

  $.msgbox = function(title, msg, cb) {
    var m = $('#msgbox-modal');

    if (typeof msg === 'function') { cb = msg; msg = title; title = U; }

    if (!m.length)
    {
      m = $('<div id="msgbox-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">OK</button></div></div></div></div>')
          .appendTo('body').modal({ show: false });
    }

    m.data('result', false)
     .toggleClass('no-title', !!!title)
     .find('.modal-header').html(title).toggle(!!title).end()
     .find('.modal-body').html(msg).end()
     .one('hidden.bs.modal', function() { cb(); })
     .modal('show');
  };

  $(function() {

    $(document).on('show.bs.modal', '.modal', function() {
      var zIndex = 2050 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function() {
        $('.modal-backdrop:not(.modal-stack)')
          .css('z-index', zIndex - 1)
          .addClass('modal-stack');
      }, 0);
    });

    
    $(document).on('shown.bs.modal', '.modal', function() {
      openModals.push(this);
      //I don't have this function
      $(this).find('select[data-statefilter], select[data-zipfilter]').change();
      // $(this).enableSelect2();
    });

    $(document).on('hidden.bs.modal', '.modal', function() {
      openModals.pop(); // shouldn't need reference to "this"
      $(this).find('textarea').each(function() {
        var id = $(this).data('tinymce_id');
        if (id) {
          tinyMCE.EditorManager.execCommand('mceRemoveEditor', true, id);
        }
      });
    });

    $(document).on('click', '[data-trigger=modal]', function(e) {
      var o = $(e.target),
          u = o.attr('href') || o.data('target');
  
      e.preventDefault();
      if (u) { $.modal(u); }
    });

  });

})(self);