(function(W) {
  'use strict';

  var $ = W.jQuery;

  function Queue() {
    var promise = $.when();
    return function(cb) {
      promise = promise
        .then(null, function(err) {
          console.log(err);
          return $.when();
        })
        .then(cb);
      return promise;
    };
  }

  var theQueue = Queue();
  $.fnQueue = function(fn, success, err) {
    switch(arguments.length) {
      case 1: return theQueue(fn);
      case 2: return theQueue(fn).then(success);
      case 3: return theQueue(fn).then(success, err);
      default:
        throw new Error('invalid number of arguments');
    }
  };

})(self);