(/*jshint unused:false*/function(W, U) {
  "use strict";

  var $ = W.jQuery,
      Promise = W.Promise,
      toastr = W.toastr;

  $.fn.dtp = function(options) {
    options = options || {};

    this.each(function(idx, element) {
      var $element = $(element);
      var $target = $element;
      var $input = $element;
      var value = null;
      if ($element.prop('tagName') === 'INPUT' && $element.hasClass('form-control') && $element.parent().hasClass('input-group')) {
        $target = $element.parent();
        value = $input.val();
      } else if ($element.hasClass('input-group') && $element.find('input.form-control').length) {
        $input = $element.find('input.form-control');
        value = $input.val();
      }
      var dataFormat = $element.data('date-format');
      if (!options.format && dataFormat) options.format = dataFormat;
      value = options.format ? moment(value, options.format) : moment(value);
      if (value.isValid())
        options.defaultDate = value;
      var modalBody = $target.closest('.modal-body');
      if (modalBody.length) {
        options.widgetParent = 'body';
        console.log(options.widgetParent);
        $target.addClass('is-dtp-datepicker');
      }
      $target.addClass('date');
      if (!options.hasOwnProperty('sideBySide'))
        options.sideBySide = true;
      $target.datetimepicker(options);
    });
  };

  $.fn.extend({
    followFrom: function (pos, css1, css2) {
      var $this = this,
          $window = $(window);

      $window.scroll(function(){
        if ($window.scrollTop() < pos) {
          $this.css(css1);
        } else {
          $this.css(css2);
        }
      });
    },
    followTo: function (pos, css1, css2) {
      var $this = this,
          $window = $(window);

      $window.scroll(function(){
        if ($window.scrollTop() > pos) {
          $this.css(css1);
        } else {
          $this.css(css2);
        }
      });
    }
  });

  W.util = {
    formatCurrency: function(amount){
      if(amount){
        if(typeof amount !== 'string' && amount.toFixed(2) != null){
          amount = amount.toFixed(2).toString();
        }
        return '$' + amount.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
      return '$0.00';
    },
    safeGet: function(data, pathArray) {
      var result = data;

      for(var i = 0; i < pathArray.length; i++) {
        var tmp = result[pathArray[i]];
        if(tmp !== undefined) {
          result = tmp;
        } else {
          return null;
        }
      }

      return result;
    },

    generateUUID: function () {
      var d = new Date().getTime();
      if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid;
    },
  };

  // $.modal = function(url, options) {
  //   var $M = $('#remote-modal'),
  //       opt = options || {};

  //   if (arguments.length === 0) { return $M; }
  //   if (!$M.length) {
  //     $M = $('<div id="remote-modal"><div class="modal-dialog"><div class="modal-content"/></div></div>')
  //       .css('display', 'none')
  //       .appendTo('body')
  //       .modal({ show: false });
  //   }

  //   return new Promise(function(resolve) {
  //     $.get(url).done(function(html) {
  //       $M.data('result', false)
  //         .removeClass()
  //         .addClass('modal fade')
  //         .addClass(opt.class)
  //         .find('.modal-content').html(html).end()
  //         .one('hidden.bs.modal', function() { resolve($M.data('result')); })
  //         .removeClass('fade')
  //         .modal('show');
  //     }).fail(function(err){
  //       toastr['error'](err.message || 'Error Loading Modal');
  //     });
  //   });
  // };

  $(document).on('click', '[data-trigger=modal]', function(e) {
    var o = $(e.target),
        u = o.attr('href') || o.data('target');

    e.preventDefault();
    if (u) { $.modal(u); }
  });
}(self));
